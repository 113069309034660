import React from "react";
import CardMedia from "@mui/material/CardMedia";

import { GlobalContext } from "../../Context/GlobalContext";

/**
 * Componente de avatar: <br>
 * Imagen redondeada que se muestra en los mensajes del robot
 * @class Components/Avatar
 * @example
 * <Avatar />
 * @returns {JSX.Element} Avatar
 */
function Avatar({show, image, className, onClick}) {
  const context = React.useContext(GlobalContext);
  const { cssApi, customApi } = context ? context : { cssApi:{
      background: "transparent",
      avatar_border: "none",
      avatar_border_radius: "30px",
    }, customApi:{} };
  /**
   * Estilos del avatar
   */
  const AvatarStyle = {
    bgcolor: cssApi.background ,
    border: cssApi.avatar_border,
    borderRadius: cssApi.avatar_border_radius + "!important",
    height: "42px",
    objectFit: "contain",
    width: "42px",
    marginTop: "-12px",
    marginLeft:"-12px",
    marginRight:"12px"
  };

  return show ? (
    <CardMedia className={className} onClick={onClick}
      component="img"
      sx={AvatarStyle}
      image={image || customApi.avatar}
      alt={"Avatar ChatBot"}
    />
  ) : <div style={AvatarStyle}></div>;
}

export default Avatar;
