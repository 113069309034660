import moment from 'moment';

const getExtension = (filename) => {
  const parts = filename.split(".");
  return parts[parts.length - 1];
};

const formatDate = (us_format) => {
  return moment.utc(us_format).format('DD/MM/YYYY');
}

const objectToQueryString = (obj) => {
  const parts = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(obj[key]);
      parts.push(`${encodedKey}=${encodedValue}`);
    }
  }
  return parts.join('&');
}

const checkType = (filename) => {

  let parts = filename.split("/");
  if(/audio(.*)\.mp4/.test(parts[parts.length - 1])){
    return "audio";
  }

  const ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case "jpg":
    case "jpeg":
    case "gif":
    case "bmp":
    case "png":
      return "img";
    case "mp4":
    case "mpg":
    case "avi":
    case "mov":
      return "video";
    case "au":
    case "snd":
    case "mid":
    case "wma":
    case "aac":
    case "mpeg":
    case "mp3":
    case "wav":
    case "m3u":
    case "webm":
      return "audio";
    default:
      return "file";
  }
};

const scrollDownContainer = (target, timeout)=>{
  setTimeout(function (){
    target.scroll({ top: target.scrollHeight, behavior: "smooth" });
  },0);
  setTimeout(function (){
    target.scroll({ top: target.scrollHeight, behavior: "smooth" });
  },timeout || 612);
}

const scrollDownEvent = () =>{
  setTimeout(()=>{
      const container = document.querySelector(".chatbothr_containerchat");
      if(container){
        container.dispatchEvent(new CustomEvent("DOMNodeInserted"));
      }
    },
    300);
}

const checkDeviceType = () => {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
    // ||
    // (navigator.platform === "MacIntel" &&
    //   navigator.maxTouchPoints > 1 &&
    //   !window.MSStream)
  ) {
    return "Android";
  }else if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      (navigator.platform === "MacIntel" &&
          navigator.maxTouchPoints > 1 &&
          !window.MSStream)
  ) {
    return "iPhone";
  } else {
    return "Desktop";
  }
};
/**
 * Establece el scroll en top 0 y previene los eventos de scrolling durante 0.6 segundos en dispositivos móviles
 */
const preventScrolling = () => {
  if (checkDeviceType() !== "Desktop") {
    window.scrollTo({
      top: 0,
    });
    window.document.body.style = "overflow-y:hidden!important;touch-action: none!important";
    setTimeout(() => {
      window.document.body.style = "overflow-y:scroll!important";
    }, 600);
  }
};

const cyrb53 = (str, seed = 0) => {
  let h1 = 0xdeadbeef ^ seed,
      h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }

  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

const getFlagIcon = (countryCode) => {
  return "fi fis fi-" + countryCode.toLowerCase();
}

const focusInput = (clazz, millis=300) => {
  setTimeout(function (){
    const input = document.querySelector("." + clazz);
    input.focus();
  }, millis);
}

const parseUrl = (url, removeParams = ['renew']) => {
  const [uri, querystring] = (url || window.location.href).split('?');

  const params = {};
  const originalParams = {};
  if (querystring) {
    querystring.split('&').forEach(function(param) {
      let [key, value] = param.split('=');
      key = key ? decodeURIComponent(key) : null;
      value = value ? decodeURIComponent(value) : null;

      originalParams[key] = value;
      params[key] = value;
    });
  }

  let tmpQuerystring = '';
  if(removeParams.length) {
    removeParams.forEach(function(key) {
      delete params[key];
    });

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        if (tmpQuerystring.length > 0) {
          tmpQuerystring += '&';
        }

        tmpQuerystring += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
      }
    }
    if(tmpQuerystring.length > 0) {
      tmpQuerystring = '?' + tmpQuerystring;
    }
  } else {
    tmpQuerystring = '?' + tmpQuerystring;
  }

  const realUri = uri + tmpQuerystring;

  return {
    "realUri": realUri,
    "uri": uri,
    "querystring": querystring,
    "params": originalParams,
    "hostname": window.location?.hostname || window.location?.host || uri
  }
}

export { getExtension, checkType, objectToQueryString, checkDeviceType, preventScrolling, scrollDownContainer, scrollDownEvent, formatDate, cyrb53, getFlagIcon, focusInput, parseUrl };
