import React from "react";
import i18n from './i18n';
import useAxios from "../Hooks/useAxios";
import queryString from 'query-string';
import defaultCustomization from "../Constants/customization-default.json";
import defaultStart from "../Constants/start-default.json";
import { SESSION, WIDGET_MODE } from '../Constants/Global';
import { useTranslation } from "react-i18next";

const GlobalContext = React.createContext();

/**
 * Contexto Global : <br>
 * Evento en el cual simula que el chatbot esta respondiendo.
 * @class Context/GlobalContext
 */

function GlobalProvider(props) {
  /* CSS global variables */
  const [customApi, setCustomApi] = React.useState({});
  const [cssApi, setCssApi] = React.useState({});
  const [extraCssApi, setExtraCssApi] = React.useState("");
  const [menuApi, setMenuApi] = React.useState([]);
  const [footerApi, setFooterApi] = React.useState([]);

  /* Chat global variables */
  const [chatMessages, setChatMessages] = React.useState([]);
  const [lastMessage, setLastMessage] = React.useState([]);
  const [chatProgress, setChatProgress] = React.useState(0);
  const [inputPlaceholder, setInputPlaceholder] = React.useState("");
  const [inputType, setInputType] = React.useState("text");
  const [inputMultiple, setInputMultiple] = React.useState(false);
  const [inputDisabled, setInputDisabled] = React.useState(true);
  const [fileDisabled, setFileDisabled] = React.useState(true);
  const [photoCameraDisabled, setPhotoCameraDisabled] = React.useState(true);
  const [videoCameraDisabled, setVideoCameraDisabled] = React.useState(true);
  const [audioDisabled, setAudioDisabled] = React.useState(true);
  const [isTyping, _setIsTyping] = React.useState(false);
  const recordingRef = React.useRef(null);
  const isUploadingRef = React.useRef(false);
  const [isFinished, setIsFinished] = React.useState(false);
  const [isDebugging, setIsDebugging] = React.useState(false);
  const typingInterval = React.useRef();

  /* Get data from api start and customization */
  const {s: session, fs: forceStart,sv: sessionVars} = queryString.parse(window.location.search);
  const historySession = session || localStorage.getItem(SESSION);
  const [isHistoric, setIsHistoric] = React.useState(Boolean(historySession));
  const { data, isLoading } = useAxios("start", {
    forceStart: forceStart,
    session: historySession,
  });

  const { t } = useTranslation();

  React.useEffect(() => {
    if (!isLoading) {
      /* Set data to global variables */
      let dataCustom = data ? parseDataCustomization(data[0].data) : defaultCustomization;
      let dataStart = data ? data[1].data : defaultStart;
      if(!dataStart.session && sessionVars){
          window.location.href=window.location.origin+window.location.pathname;
      }
      i18n.changeLanguage(dataCustom.lang);

      /** Set custom skin **/
      setCustomApi({
        id: dataCustom.id,
        name: dataCustom.name,
        client: dataCustom.client,
        logo: dataCustom.logo,
        avatar: dataCustom.avatar,
        session: dataStart.session,
        prefix: dataCustom.prefix,
        ui_protected: dataCustom.ui_protected,
        ui_share_disabled: dataCustom.type !== 'talent_acquisition' ?  true : dataCustom.ui_share_disabled,
      });

      /** Custom Title & Favicon **/
      if(!WIDGET_MODE){
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = dataCustom.favicon || 'https://uploads-ssl.webflow.com/62a0fbcdff8636963d744a7b/62ea2288b102af72f9ddf008_cropped-favicon-azul-32x32.png';
        let title = document.querySelector("title");
        title.innerText = dataCustom.title || "Entrevista virtual";
      }

      /** Load session **/
      if(!dataStart.session.includes("sessionfake")) {
        localStorage.setItem(SESSION, dataStart.session);
      }

      setCssApi({ ...dataCustom.css });
      setExtraCssApi(
        dataCustom.extra_customization
          ? dataCustom.extra_customization
          : defaultCustomization.extra_customization
      );
      setMenuApi(dataCustom.menu_header);
      setFooterApi(dataCustom.menu_footer);

      setIsDebugging(dataCustom.debugging)

      /* Set chat messages */
      pushChatMessages(dataStart, "chatbot");
    }else{
        setIsTyping(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const setIsTyping = function (value, delay=false){
      clearInterval(typingInterval.current);
      if(delay){
          typingInterval.current = setTimeout(()=>_setIsTyping(value), (Math.round(1000*Math.random())+1500));
      }else{
          _setIsTyping(value);
      }
  }
  /**
   * Agrega los datos default en caso de faltar alguno de los campos principales
   * - css
   * - menu_footer
   * - menu_header
   * - extra_customization
   * - avatar
   * - logo
   * - etc
   * @param {*} dataCustom respuesta del enpoint customization
   * @returns {*} datos default agregados
   */
  const parseDataCustomization = (dataCustom) => {
    // llenamos campos missing
    let result = {...defaultCustomization, ...dataCustom};
    // campos missing dentro de css
    result.css = {...defaultCustomization.css, ...dataCustom.css};

    // llenamos campos undefined o null
    if (!result.menu_footer){
      result.menu_footer = defaultCustomization.menu_footer;
    }
    if (!result.menu_header){
      result.menu_header = defaultCustomization.menu_header;
    }
    if (!result.id){
      result.id = defaultCustomization.id;
    }
    if (!result.avatar){
      result.avatar = defaultCustomization.avatar;
    }
    if (!result.name){
      result.name = defaultCustomization.name;
    }
    if (!result.client){
      result.client = defaultCustomization.client;
    }
    if (!result.logo){
      result.logo = defaultCustomization.logo;
    }
    if (!result.extra_customization){
      result.extra_customization = defaultCustomization.extra_customization;
    }

    return result;
  }
  /**
   * Ajusta los mensajes que viene del reply
   * @param {*} message Array de mensajes que responde el servicio del reply.
   * @param {*} type Tipo de mensaje si es del chatbot o del user.
   */
  const pushChatMessages = (message, type) => {
    if(isFinished) return;
    let newcChatMessages = [...chatMessages];
    if (type === "chatbot") {
      if (message) {
        let chatbotMsg = {
          data: message.response ? message.response : [],
          progress: message.progress ? message.progress : 0,
          typeUser: 1,
        };
        newcChatMessages.push(chatbotMsg);
      }

      if(message.response.length && message.response[message.response.length-1].type === "END"){
          setIsFinished(true);
          localStorage.removeItem(SESSION);
      }

      setChatMessages(newcChatMessages);
    } else {
      message.typeUser = 2;
      if(inputType === 'password'){
          message.text = "********";
          message.messages[0].selected_text = message.text;
      }
      const currentMessage = newcChatMessages[newcChatMessages.length-1].data
      currentMessage[currentMessage.length-1].user_reply = message.postback
      newcChatMessages.push(message)

      setChatMessages(newcChatMessages);
    }
  };

  const pushChatMessageTimeout = (message) => {
    message = message || "replyTimeOut";
    const item = {text: t(message), postback: "TIMEOUT"};
    pushChatMessages({ messages: [{selected_text: item.text, type_text: 'text' }], postback: item.postback, text: item.text}, 'user');
  }

  React.useEffect(() => {
      if (chatMessages.length){
          const last_block = chatMessages.length-1; // Ultimo bloque de mensajes
          if(chatMessages[last_block].data && chatMessages[last_block].data.length){
              const last_msg = chatMessages[last_block].data.length-1  // Ultimo mensaje del bloque
              setLastMessage(chatMessages[last_block].data[last_msg])
          }
    }
  }, [chatMessages]);

  return (
    <GlobalContext.Provider
      value={{
        isLoading,
        customApi,
        cssApi,
        extraCssApi,
        menuApi,
        footerApi,
        chatMessages,
        setChatMessages,
        chatProgress,
        setChatProgress,
        inputPlaceholder,
        setInputPlaceholder,
        inputType,
        setInputType,
        inputMultiple,
        setInputMultiple,
        inputDisabled,
        setInputDisabled,
        fileDisabled,
        setFileDisabled,
        photoCameraDisabled,
        setPhotoCameraDisabled,
        videoCameraDisabled,
        setVideoCameraDisabled,
        audioDisabled,
        setAudioDisabled,
        pushChatMessages,
        pushChatMessageTimeout,
        lastMessage,
        isHistoric,
        setIsHistoric,
        isTyping,
        recordingRef,
        isUploadingRef,
        isFinished,
        setIsTyping,
        isDebugging
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
}

export { GlobalContext, GlobalProvider };
