import React from "react";
import "./WebCamRecorder.css";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';
import SendIcon from '@mui/icons-material/Send';
import VideocamIcon from '@mui/icons-material/Videocam';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { GlobalContext } from "../../Context/GlobalContext";
import { TimerContext } from '../../Context/TimerContext'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles';

const MediaSelect = ({ id, name, title, value, optionsArray, handleChange }) => {
  return (<>
    <InputLabel id={`label-${id}`}>{title}</InputLabel>
    <Select
      labelId={`label-${id}`}
      id={id}
      name={name}
      value={value}
      label={title}
      onChange={handleChange}
      autoWidth
    >
      {optionsArray.map(option => (
        <MenuItem key={option.value} value={option.value} >{option.label}</MenuItem>
      ))}
    </Select>
  </>);
}

const SourcesWrapper = React.memo(({videoSource, videoSourceOptions, audioSource, audioSourceOptions, setVideoSource, setAudioSource}) => {
  const { t } = useTranslation();

  return <div>
    <FormControl sx={{ m: 1, minWidth: 120 }}>
      <MediaSelect name="videoSource" id="videoSource" title={t("webcamrecorder.video.inputtitle")} value={videoSource} optionsArray={videoSourceOptions} handleChange={(e) => setVideoSource(e.target.value)}/>
    </FormControl>

    <FormControl sx={{ m: 1, minWidth: 120 }}>
      <MediaSelect id="audioSource" name="audioSource" title={t("webcamrecorder.audio.inputtitle")} value={audioSource} optionsArray={audioSourceOptions} handleChange={(e) => setAudioSource(e.target.value)}/>
    </FormControl>
  </div>;
});

/**
 * @class Components/WebCamRecorder
 * <WebCamRecorder />
 */
function WebCamRecorder({ uploadFiles, onUploadEnd, showSources = false, showOptionsButton = true, showDownloadLink = false}) {
  //const isDebug = window.location.search.indexOf('debug')>-1;
  const theme = useTheme();
  const isSmallDesktop = useMediaQuery(theme.breakpoints.down('lg'));

  const { t } = useTranslation();
  const { recordingRef, pushChatMessageTimeout, isUploadingRef } = React.useContext(GlobalContext);
  const { timerState } = React.useContext(TimerContext);

  const hasTimerRef = React.useRef(null);
  const isTimerRunningRef = React.useRef(null);
  const videoRef = React.useRef(null);

  // Variable para la gestión del stream, para evitar que al renderizar varias veces perdamos su valor
  const streamRef = React.useRef(null);

  // Usamos referencia para tener disponible el dato aun cuando se renderice
  const streamRecorderRef = React.useRef(null);

  const skipQuestionRef = React.useRef(false);

  // En lugar de usarlo como estado lo usamos como Ref, para evitar que renderice cada vez que cambia
  const chunks = React.useRef([]);

  const [isMediaLoading, setIsMediaLoading] = React.useState(true);
  const [isRecording, setIsRecording] = React.useState(false);
  const [cantRecord, setCantRecord] = React.useState(false);

  const [isReadyForUpload, setIsReadyForUpload] = React.useState(false);

  const [audioSource, setAudioSource] = React.useState('');
  const [videoSource, setVideoSource] = React.useState('');

  const [audioSourceOptions, setAudioSourceOptions] = React.useState([]);
  const [videoSourceOptions, setVideoSourceOptions] = React.useState([]);

  const [hasRecordedDataAvailable, setHasRecordedDataAvailable] = React.useState(false);
  const [currentBlob, setCurrentBlob] = React.useState(null);
  const [downloadLink, setDownloadLink] = React.useState('');

  const [showOptions, setShowOptions] = React.useState(false);

  const [hasTimer, setHasTimer] = React.useState(false);
  const [isTimerRunning, setIsTimerRunning] = React.useState(false);

  const [aspectRatio, setAspectRatio] = React.useState(1);
  const [videoMaxWidth, setVideoMaxWidth] = React.useState('initial');
  const [error, setError] = React.useState('');

  const getSupportedMimeType = function() {
    const list = [
      "video/webm;codecs=vp9,opus",
      "video/mp4;codecs=vp9,opus",
      "video/webm;codecs=h264",
      "video/mp4;codecs=h264",
      "video/webm",
      "video/mp4",
    ];

    for(let i=0; i<list.length; i++) {
      if(MediaRecorder.isTypeSupported(list[i])) {
        return list[i];
      }
    }

    return list[list.length-1];
  }

  const mediaRecorderOptions = function() {
    return {
      "mimeType": getSupportedMimeType(),
      "videoBitsPerSecond": 500000,
      "audioBitsPerSecond": 64000
    };
  }

  function startRecording() {
    // Si ya estamos grabando o si no tenemos un streaming cargado, abortamos
    if(isRecording || !streamRef.current) {
      return;
    }

    setDownloadLink('');
    streamRecorderRef.current = new MediaRecorder(streamRef.current, mediaRecorderOptions());
    streamRecorderRef.current.start(); // Comenzamos a grabar
    streamRecorderRef.current.ondataavailable = function(event) {
      chunks.current.push(event.data);
      setHasRecordedDataAvailable(true);
    }
    setIsRecording(true);
  }

  const stopTracks = () => {
    streamRef.current.getTracks().forEach(track => {
      track.stop();
    });
  }

  function uploadRecording() {
    isUploadingRef.current = true;

    const file = new File([currentBlob], "recording.mp4", { type: currentBlob.type });
    uploadFiles([file]).then(() => {
      onUploadEnd && onUploadEnd()
      isUploadingRef.current = false;
      setCurrentBlob(false);
    });
  }

  React.useEffect(() => {
    hasTimerRef.current = hasTimer;
    isTimerRunningRef.current = isTimerRunning;
  }, [hasTimer, isTimerRunning]);

  React.useEffect(() => {
    setHasTimer((prev) => prev || timerState.init);
    setIsTimerRunning(timerState.isRunning);
  }, [timerState.isRunning, timerState.time]);

  // Al desmontar el componente paramos el stream
  React.useEffect(() => {
    return () => {
      if(!isUploadingRef.current && hasTimerRef.current && !isTimerRunningRef.current) {
        pushChatMessageTimeout(skipQuestionRef.current ? "webcamrecorder.skip.message" : null);
      }

      skipQuestionRef.current = false;

      recordingRef.current = null;
      stopTracks();
    };
  }, []);

  // Cuando un Ref externo quita el grabando, se sube el video
  React.useEffect(()=> {
    if(isRecording){
      recordingRef.current = () => {
        recordingRef.current = false;
        setCantRecord(true);
        stopRecording();
        return true;
      }
    } // eslint-disable-next-line
  }, [isRecording]);

  // Al momento de parar la grabación, descargamos el trozo de grabación creado
  React.useEffect(function() {
    if(isRecording || !hasRecordedDataAvailable || chunks.current.length === 0) {
      return;
    }

    const blob = new Blob(chunks.current, {
      type: getSupportedMimeType()
    });

    setCurrentBlob(blob);

    const url = URL.createObjectURL(blob);

    setDownloadLink(url);
    setIsReadyForUpload(true);
    setHasRecordedDataAvailable(false);

    chunks.current = [];
  }, [isRecording, hasRecordedDataAvailable]);

  function skipQuestion() {
    skipQuestionRef.current = true;
    pushChatMessageTimeout("webcamrecorder.skip.message");
  }

  function stopRecording() {
    if(!isRecording || !streamRecorderRef.current) {
      return;
    }

    streamRecorderRef.current.stop(); // Comenzamos a grabar
    setIsRecording(false);
  }

  React.useEffect(function() {
    async function prepareStream() {
      function gotStream(stream) {
        streamRef.current = stream;

        if(videoRef.current) {
          videoRef.current.srcObject = stream;

          setTimeout(() => {
            try {
              setAspectRatio(stream.getVideoTracks()[0].getSettings().aspectRatio);
            } catch (e) {
              setAspectRatio(1);
            } finally {
              setIsMediaLoading(false);
            }
          }, 500)
        }
      }

      function getDevices() {
        return navigator.mediaDevices.enumerateDevices();
      }

      async function getStream() {
        // Si tenemos más de un stream, paramos el resto
        if(streamRef.current) {
          stopTracks();
        }

        const constraints = {
          audio: {deviceId: audioSource !== '' ? {exact: audioSource} : undefined},
          video: {deviceId: videoSource !== '' ? {exact: videoSource} : undefined}
        };

        try {
          const stream = await navigator.mediaDevices.getUserMedia(constraints);
          gotStream(stream);
        } catch(error) {
          setError(error);
        }
      }

      function gotDevices(deviceInfos) {
        const audioSourceOptions = [];
        const videoSourceOptions = [];

        for(const deviceInfo of deviceInfos) {
          if(deviceInfo.kind === 'audioinput') {
            audioSourceOptions.push({
              value: deviceInfo.deviceId,
              label: deviceInfo.label || `Microphone ${deviceInfo.deviceId}`
            })
          } else if (deviceInfo.kind === 'videoinput') {
            videoSourceOptions.push({
              value: deviceInfo.deviceId,
              label: deviceInfo.label || `Camera ${deviceInfo.deviceId}`
            })
          }
        }

        if(audioSource === '') {
          let audioDevices = deviceInfos.filter(device => device.kind === 'audioinput');
          if(audioDevices[0] && audioDevices[0].deviceId) {
            setAudioSource(audioDevices[0].deviceId);
          }
        }

        if(videoSource === '') {
          let videoDevices = deviceInfos.filter(device => device.kind === 'videoinput');
          if(videoDevices[0] && videoDevices[0].deviceId) {
            setVideoSource(videoDevices[0].deviceId);
          }
        }

        setAudioSourceOptions(audioSourceOptions);
        setVideoSourceOptions(videoSourceOptions);
      }

      await getStream();
      const mediaDevices = await getDevices();
      gotDevices(mediaDevices);
    }

    prepareStream();
  }, [audioSource, videoSource]);

  const RecordButton = function() {
    let classes = `recording-dot ${isRecording ? 'is-recording':''}`;

    return <>
      <div className={classes}></div>
    </>;
  }


  React.useEffect(() => {
    if(isSmallDesktop) {
      setVideoMaxWidth(isSmallDesktop ? '400px' : 'initial');
    }
  }, [isSmallDesktop]);

  return (<div className={[`webcamrecorder-wrapper`, aspectRatio >= 1 ? 'ar-landscape' : 'ar-portrait'].join(' ')}>

    <div style={{
      maxWidth: videoMaxWidth,
      margin: '0 auto'
    }}>
      <div className="videotag-wrapper">
        <video className="webcam-recorder" ref={videoRef} autoPlay muted playsInline style={{ display: downloadLink ? 'none' : 'inherit' }} />
        {downloadLink && <video className="webcam-player" src={downloadLink} controls playsInline />}
      </div>
      <RecordButton/>

    </div>

    <Stack direction="row"
           justifyContent="space-around"
           alignItems="center"
           spacing={2}
           mt={2}>
      {!isRecording && <Button variant="contained" onClick={startRecording} disabled={isRecording || cantRecord || isMediaLoading} endIcon={<VideocamIcon />}>{downloadLink ? t("webcamrecorder.repeat.video") : t("webcamrecorder.record.video")}</Button>}
      {isRecording && <Button color="secondary" variant="contained" onClick={stopRecording} endIcon={<StopCircleIcon />}>{t("webcamrecorder.stop.video")}</Button>}
      <Button variant="outlined" onClick={uploadRecording} disabled={!isReadyForUpload} endIcon={<SendIcon />}>
        {t("webcamrecorder.send.video")}
      </Button>

      {showDownloadLink && downloadLink && (
        <Button variant="text" href={downloadLink} download="recording.mp4">{t('webcamrecorder.download.video')}</Button>
      )}
      {((hasTimer && !isTimerRunning) || downloadLink) && (
        <Button variant="text" onClick={skipQuestion}>{t('webcamrecorder.skip.video')}</Button>
      )}
    </Stack>

    {!isRecording && !cantRecord && <Stack direction="column"
           justifyContent="center"
           alignItems="center"
           spacing={2}
           mt={2}>
      {showOptionsButton && <Button variant="text" onClick={() => setShowOptions(!showOptions)}>{t("webcamrecorder.media.options")}</Button>}
      {showOptions && showSources && <SourcesWrapper videoSource={videoSource} videoSourceOptions={videoSourceOptions} audioSource={audioSource} audioSourceOptions={audioSourceOptions} setVideoSource={setVideoSource} setAudioSource={setAudioSource} />}
    </Stack>}

    <div>{error && <p>{error.message}</p>}</div>
  </div>)
}

export default WebCamRecorder;
