import React from "react";
import parse from 'html-react-parser';
import { Box, CardMedia, Typography } from "@mui/material";
import LayoutMessage from "../LayoutMessage/LayoutMessage";
import Carousel from "../Carousel/Carousel";
import ButtonPanel from "../ButtonPanel/ButtonPanel";
import {Timer} from "../Timer/Timer";
import MultiSelect from "../MultiSelect/MultiSelect";
import Scheduling from "../Scheduling/Scheduling";
import { GlobalContext } from "../../Context/GlobalContext";
import { MAX_BUTTONS } from "../../Constants/Global";

/**
 * Componente Bocadillo chatbot: <br>
 * Render del bocadillo del chatbot en el container del chat.
 * @class Components/MessageChatBot
 * @example
 * import MessageChatBot from "../../Components/MessageChatBot/MessageChatBot";
 * <MessageChatBot msg={msg} key={index.toString()} />
 * @param {*} msg Respuesta del mensaje que viene del API.
 * @param {*} key Index del recorrido de la lista.
 * @returns {React.Fragment} MessageChatBot
 */

function MessageChatBot({ msg }) {
  const {
    customApi,
    setInputPlaceholder,
    setInputType,
    setInputMultiple,
    setInputDisabled,
    setFileDisabled,
    setPhotoCameraDisabled,
    setVideoCameraDisabled,
    setAudioDisabled,
  } = React.useContext(GlobalContext);

  React.useEffect(() => {
    setInputPlaceholder(msg.help_text);
    validateButtons(msg);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Función para desactivar los botones depende del tipo del mensaje del chatbot
   * @param {*} msg Tipo de mensaje del chatbot
   */
  const validateButtons = (msg) => {
    const defaultData = {
      setInputType: "text",
      setInputMultiple: msg?.response_multiple,
      setInputDisabled: false,
      setFileDisabled: true,
      setPhotoCameraDisabled: true,
      setVideoCameraDisabled: true,
      setAudioDisabled: true
    }

    // bloqueamos si se pide, si es multiple (hay que elegir varias), o si tiene botones y no se fuerza un tipo
    if (msg.response_type === "blocked" || msg.response_type === "multiple" ||
        (!msg.response_type && (msg.options.length > 0 || msg.items.length > 0))
    ) {
      defaultData.setInputDisabled = true;
    } else if (msg.response_type === "file") {
      defaultData.setInputDisabled = true;
      defaultData.setFileDisabled = false;
    } else if (msg.response_type === "photo") {
      defaultData.setInputDisabled = true;
      defaultData.setFileDisabled = false;
      defaultData.setPhotoCameraDisabled = false;
    } else if (msg.response_type === "video") {
      defaultData.setInputDisabled = true;
      defaultData.setFileDisabled = customApi.ui_protected; //Videoentrevista: Si esta protegido no dejamos subir video
      defaultData.setVideoCameraDisabled = false;
    } else if (msg.response_type === "phone") {
      defaultData.setInputType = "tel";
    } else if (msg.response_type === "audio") {
      defaultData.setInputDisabled = true;
      defaultData.setAudioDisabled = false;
    }
    else {
      defaultData.setInputType = (msg.response_type === "numeric" ? "number" : msg.response_type);
    }
    console.log("defaultData", defaultData);
    setInputType(defaultData.setInputType);
    setInputMultiple(defaultData.setInputMultiple);
    setInputDisabled(defaultData.setInputDisabled)
    setFileDisabled(defaultData.setFileDisabled)
    setPhotoCameraDisabled(defaultData.setPhotoCameraDisabled)
    setVideoCameraDisabled(defaultData.setVideoCameraDisabled)
    setAudioDisabled(defaultData.setAudioDisabled)
  };

  /**
   * Función para validar el tipo de botoneras del mensaje.
   * @param {*} options Lista de botones.
   * @param {*} response_multiple Flag para validar si es multiSelect.
   * @param {*} help_text Placeholder del select.
   */
  const displayButtons = (options, response_multiple, help_text) => {
    if (options && options.length > 0) {
      const isDisabled = "user_reply" in msg;
      if (response_multiple || options.length > MAX_BUTTONS) {
        return (
          <MultiSelect
            items={options}
            multiple={response_multiple}
            placeholder={help_text}
            forceDisabled={isDisabled}
          />
        );
      } else {
        return <ButtonPanel items={options} forceDisabled={isDisabled} />;
      }
    }
  };

  /**
   * Función para validar el tipo de media y su formato.
   * @param {*} options Lista de botones.
   * @param {*} response_type Flag para validar si es multiSelect.
   * @param {*} help_text Placeholder del select.
   */
  const displayMedia = (media) => {
    switch (media.type){
      case "embed":
        return (<iframe
            width="480"
            height="320"
            src={media.url}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube video"
        />);
      case "scheduling":
        return <Scheduling link={media.url}/>
      default: // imagen o video
        const cmpType = media.type === "video" ? "video" : "img";
        return (<CardMedia
            component={cmpType}
            controls
            sx={{ marginTop: "15px", width: "100%", maxWidth: "400px" , borderRadius: "14px" }}
            image={media.url}
            alt={"Imagen " + media.id}
        />);
    }

  };


  return (
    <React.Fragment>
      <LayoutMessage withAvatar={msg.withAvatar} typeUser={1}>
        {msg.selected_text && <Typography align="left" sx={{ fontSize: "1em", wordWrap: "break-word", overflowWrap: "anywhere" }}>
          {parse(msg.selected_text)}
        </Typography>}
        {/* Type Message: Image */}
        {msg.multimedia &&
          msg.multimedia.length > 0 &&
          msg.multimedia.map((media) => (
            <Box display="flex" justifyContent="center" key={media.id}>
              {displayMedia(media)}
            </Box>
          ))}
        {/* Type Message: Image TODO: Simplificar el type multiple a partir de Septiembre */}
        {displayButtons(msg.options, (msg.response_type === 'multiple' || msg.response_multiple), msg.help_text)}
        {msg.reply_time && !msg.user_reply && <Timer/>}
      </LayoutMessage>
      {/* Type Message: Carousel */}
      {msg.items && msg.items.length > 0 && (
        <LayoutMessage withAvatar={false} typeUser={1} isCarousel={true}>
          <Carousel items={msg.items} forceDisabled={"user_reply" in msg} />
        </LayoutMessage>
      )}
    </React.Fragment>
  );
}

export default MessageChatBot;
