import React from "react";
import "./MenuHeader.css";
import { IconButton, Menu } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import MenuIcon from "@mui/icons-material/Menu";
import MenuListHeader from "../MenuListHeader/MenuListHeader";
import {GlobalContext} from "../../Context/GlobalContext";
import {ShareSocial} from 'react-share-social'
import { useTranslation } from 'react-i18next';

/**
 * Componente Menú del header: <br>
 * Icono de la hamburguesa con la funcionalidad del menú.
 * @class Components/MenuHeader
 * @example
 * import MenuHeader from "../../Components/MenuHeader/MenuHeader";
 * <MenuHeader menuList={menuApi} />
 * @param {*} menuList Variable con la lista del menú que viene del API.
 * @param debugging
 * @returns {React.Fragment} MenuHeader
 */

function MenuHeader({ menuList, debugging }) {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [urlToShare, setUrlToShare] = React.useState(null);
    const [openModal, setOpenModal] = React.useState(false);
    const { cssApi, customApi } = React.useContext(GlobalContext);
    const open = Boolean(anchorEl);

  React.useEffect(() => {
    document.addEventListener("sharedMenu", function(e){
      setUrlToShare(e.detail);
      setOpenModal(true);
    });
  }, []);
  /**
   * Función para abrir el popup del menú
   */
  const onClickMenuHandler = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  /**
   * Función para cerrar el popup del menú
   */
  const onCloseMenuHandler = () => {
    setAnchorEl(null);
  };

  const getUrlToShare = () => {
    if(urlToShare) return urlToShare;
    let url = window.location.href.replace("nid=", "shared_by=");
    url = url.includes("?") ? url+"&" : url+"?";
    url += 'rec=Compartido';
    return url
  };
  /**
   * Función render de los items del menú
   */
  const displayOptions = () => {
    const shareLink  = customApi.ui_share_disabled ? (
      ""
      ) : <MenuListHeader
      item={{
        action: "share",
        label: t("shareLink"),
        value: "share",
      }}
      key={"share"}
      afterClick={() => {
        setOpenModal(true);
        onCloseMenuHandler();
      }}
    />;
    return [
      ...menuList.map((item) => (
        <MenuListHeader item={item} key={item.label} afterClick={onCloseMenuHandler} />
      )),
      shareLink,
      <MenuListHeader
        item={{
          action: "remove_session",
          label: t("restartConversation"),
          value: "reiniciar",
        }}
        key={"reiniciar"}
        afterClick={onCloseMenuHandler}
      />,
        (debugging && <MenuListHeader
            item={{
                action: "view_session",
                label: t("viewCurrentSession"),
                value: "view_session",
            }}
            key={"session"}
            afterClick={onCloseMenuHandler}
        />)
    ];
  };

  return (
    <React.Fragment>
      <IconButton
        id="menu_button"
        size="large"
        edge="end"
        color="inherit"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        aria-label="header_menu"
        onClick={onClickMenuHandler}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="header_menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onCloseMenuHandler}
        MenuListProps={{
          "aria-labelledby": "menu_button",
        }}
        sx={{ marginTop: "10px"}}
      >
        {displayOptions()}
        <div className="powered-by"><img alt={"Logo Hr bot factory"} height={30} src="https://d2t3vz512yrf7u.cloudfront.net/brand/poweredbyhrbf.png"/></div>
      </Menu>
    <Dialog
        open={openModal}
        onClose={()=>{
          setUrlToShare(null);
          setOpenModal(false);
        }}
        maxWidth={"90%"}
    >
            <ShareSocial
                title={t("shareLink")}
                url={t("shareLinkText") + " " + getUrlToShare()}
                socialTypes= {['whatsapp','facebook','twitter', 'telegram', 'linkedin']}
                onSocialButtonClicked={ data => console.log(data)}
                style={{
                    root: {
                        background: cssApi.chatbox_color_bot,
                        borderRadius: 3,
                        border: 0,
                        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                        color: 'white',

                    },
                    iconContainer: {textAlign: 'center'},
                    copyContainer: {
                        border: '1px solid blue',
                        background: 'rgb(0,0,0,0.7)'
                    },
                    title: {
                        color: 'aquamarine',
                        fontStyle: 'italic'
                    }
                }}
            />
    </Dialog>
    </React.Fragment>
  );
}

export default MenuHeader;
